import {
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { appMarginTop } from "../../assets/css/globalCss";
import { useAppSelector } from "../../data/redux/stores/hooks";
import { Letters, PLACE_LETTER, SET_PLACE } from "../../data/redux/types/savePlace";
import { placesEditALink } from "../../routes";
import PlaceCard from "../components/PlaceCard";
import SeparatorView from "../components/SeparatorView";
import client from '../../data/config/services/Client';
import { SaveJourneyResponse } from "../../data/dto/interfaces";
import { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { getUserAction } from "../../data/redux/actions/userActions";
import CloseIcon from '@mui/icons-material/Close';
import Place from "../../data/model/Place";
import CSS from "csstype";
import {padd5} from '../../assets/theme/globalStyles';
import {priceToStr} from '../../helpers/string.helper';

const hours = ['', '0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
const minutes = ['', '0','15','30','45'];

export default function PlacesScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useAppSelector(selector => selector.userReducer.user);
  const placeATittle = user && user.journey && user.journey.place_a ? user.journey.place_a.title : "*Casa";
  const placeA = user && user.journey && user.journey.place_a ? user.journey.place_a : undefined;

  const placeBTittle = user && user.journey && user.journey.place_b ? user.journey.place_b.title : "*Trabajo";
  const placeB = user && user.journey && user.journey.place_b ? user.journey.place_b : undefined;

  const [timeToArriveHour, setTimeToArriveHour] = React.useState('');
  const [timeToArriveMinute, setTimeToArriveMinute] = React.useState('0');
  const [exitTimeHour, setExitTimeHour] = React.useState('');
  const [exitTimeMinute, setExitTimeMinute] = React.useState('0');

  const [open, setOpen] = React.useState(false);
  const [openImcompleteDataModal, setOpenImcompleteDataModal] = React.useState(false);
  const [openCompleteDataModal, setOpenCompleteDataModal] = React.useState(false);

  const IDA_VUELTA = 'ida_vuelta'
  const IDA = 'ida'
  const VUELTA = 'vuelta'
  const MENSUAL = 'mensual'
  const QUINCENAL = 'quincenal'

  interface PricesObj {
    mensual: number;
    quincenal: number;
  }

  const pricesOptions = React.useMemo(
    () => {
      return {
        ida_y_vuelta: {
          mensual: 600000,
          quincenal: 330000
        },
        ida_o_vuelta: {
          mensual: 350000,
          quincenal: 190000
        },
      }
    },
    []
  );
  const [tipoViaje, setTipoViaje] = React.useState<string>(IDA_VUELTA);
  const [paymentOption, setPaymentOption] = React.useState<string>(MENSUAL);
  const [priceOptions, setPriceOptions] = React.useState<PricesObj>(pricesOptions.ida_y_vuelta);
  const [price, setPrice] = React.useState<number>(paymentOption === MENSUAL ? priceOptions.mensual : priceOptions.quincenal);

  React.useEffect(() => {
    console.log('tipoViaje')
    switch (tipoViaje) {
      case IDA_VUELTA:
        if (priceOptions !== pricesOptions.ida_y_vuelta) {
          setPriceOptions(pricesOptions.ida_y_vuelta)
        }
        break;
      
      case IDA:
      case VUELTA:
        if (priceOptions !== pricesOptions.ida_o_vuelta) {
          setPriceOptions(pricesOptions.ida_o_vuelta)
        }
        break;
    
      default:
        if (priceOptions !== pricesOptions.ida_y_vuelta) {
          setPriceOptions(pricesOptions.ida_y_vuelta)
        }
        break;
    }
  }, [priceOptions, pricesOptions.ida_o_vuelta, pricesOptions.ida_y_vuelta, tipoViaje]);

  React.useEffect(() => {
    console.log('paymentOption')
    switch (paymentOption) {
      case MENSUAL:
        if (price !== priceOptions.mensual) {
          setPrice(priceOptions.mensual)
        }
        break;
      
      case QUINCENAL:
        if (price !== priceOptions.quincenal) {
          setPrice(priceOptions.quincenal)
        }
        break;
    
      default:
        break;
    }
  }, [paymentOption, price, priceOptions, pricesOptions, tipoViaje]);

  const serviceTypeButtons = [
    <Button
      key="ida_vuelta"
      variant={tipoViaje === IDA_VUELTA ? 'contained' : 'outlined'}
      onClick={() => {
        setTipoViaje(IDA_VUELTA);
      }}
    >
      Ida y vuelta
    </Button>,
    <Button
      key="ida"
      variant={tipoViaje === IDA ? 'contained' : 'outlined'}
      onClick={() => {
        setTipoViaje(IDA);
      }}
    >
      Solo ida
    </Button>,
    <Button
      key={VUELTA}
      variant={tipoViaje === VUELTA ? 'contained' : 'outlined'}
      color={'primary'}
      onClick={() => {
        setTipoViaje(VUELTA);
      }}
    >
      Solo vuelta
    </Button>,
  ];

  const paymentOptionsButtons = [
    <Button
      key={MENSUAL}
      variant={paymentOption === MENSUAL ? 'contained' : 'outlined'}
      onClick={() => {
        setPaymentOption(MENSUAL);
      }}
    >
      Mensual
    </Button>,
    <Button
      key={QUINCENAL}
      variant={paymentOption === QUINCENAL ? 'contained' : 'outlined'}
      onClick={() => {
        setPaymentOption(QUINCENAL);
      }}
    >
      Quincenal
    </Button>,
  ];


  const  onTimeToArriveHourChange = (event: object, vals: any) => {
    setTimeToArriveHour(vals);
  }
  const  onTimeToArriveMinuteChange = (event: object, vals: any) => {
    setTimeToArriveMinute(vals);
  }

  const  onExitTimeHourChange = (event: object, vals: any) => {
    setExitTimeHour(vals);
  }
  const  onExitTimeMinuteChange = (event: object, vals: any) => {
    setExitTimeMinute(vals);
  }

  useEffect(() => {
    // console.log("useEffect....");
    if (user) {
      let count = 0;
      if (!user.journey || !user.journey.place_a) count++;
      if (!user.journey || !user.journey.place_b) count++;
      if (!user.journey || !user.journey.time_to_arrive) count++;
      if (!user.journey || !user.journey.exit_time) count++;
      setOpenImcompleteDataModal(count > 0);
      setOpenCompleteDataModal(count === 0);
    }
    if (user && user.journey && user.journey.time_to_arrive) {
      let splited = user.journey.time_to_arrive.split(':');
      setTimeToArriveHour(parseInt(splited[0]).toString());
      setTimeToArriveMinute(parseInt(splited[1]).toString());
    }
    if (user && user.journey && user.journey.exit_time) {
      let splited = user.journey.exit_time.split(':');
      setExitTimeHour(parseInt(splited[0]).toString());
      setExitTimeMinute(parseInt(splited[1]).toString());
    }
  }, [user]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [validForm, setValidForm] = React.useState(false);

  useEffect(() => {
    let valid = true;
    if (timeToArriveHour === '') valid = false
    if (exitTimeHour === '') valid = false
    if (placeA === undefined) valid = false
    if (placeB === undefined) valid = false
    setValidForm(valid);
  }, [exitTimeHour, placeA, placeB, timeToArriveHour]);

  return (
    <Container maxWidth="sm" style={appMarginTop}>
      <PlaceCard
        placeholder={placeATittle}
        place={placeA}
        onAction={(plc?: Place) => {
          if (plc) {
            dispatch({
              type: SET_PLACE,
              payload: placeA,
            });
          }
          dispatch({
            type: PLACE_LETTER,
            payload: Letters.PLACE_A
          });
          history.push(placesEditALink.path);
        }}
      />
      <SeparatorView />
      <PlaceCard
        placeholder={placeBTittle}
        place={placeB}
        onAction={(plc?: Place) => {
          if (plc) {}
          dispatch({
            type: SET_PLACE,
            payload: placeB,
          });
          dispatch({
            type: PLACE_LETTER,
            payload: Letters.PLACE_B
          });
          history.push(placesEditALink.path);
        }}
      />
      <SeparatorView />
      <hr />
      <SeparatorView />
      <Typography variant="h5" style={subTitle}>TIPO DE SERVICIO</Typography>
      <div style={padd5}>
        <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
          {serviceTypeButtons}
        </ButtonGroup>
      </div>
      <SeparatorView />
      <Typography variant="h5" style={subTitle}>OPCIONES DE PAGO</Typography>
      <div style={padd5}>
        <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
          {paymentOptionsButtons}
        </ButtonGroup>
      </div>
      <SeparatorView />
      <Typography variant="h4" component="h2" style={priceStr}>
        Gs. {priceToStr(price)}
      </Typography>
      <SeparatorView />
      <hr />
      <SeparatorView />
      <div>Hora de llegada al trabajo</div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            id="timeToArriveHourInput"
            options={hours}
            // defaultValue={timeToArriveHour}
            // inputValue={timeToArriveHour}
            value={timeToArriveHour}
            // onInputChange={(event, newInputValue) => {
            //   setTimeToArriveHour(newInputValue);
            // }}
            onChange={onTimeToArriveHourChange}
            renderInput={(params) => <TextField {...params} label="Hora" />}
            style={timeToArriveHour.length > 0 ? undefined : redBorder}
          />
        </Grid>
        <Grid item xs={6}>
        <Autocomplete
            disablePortal
            id="timeToArriveMinuteInput"
            options={minutes}
            // defaultValue={'0'}
            value={timeToArriveMinute}
            onChange={onTimeToArriveMinuteChange}
            renderInput={(params) => <TextField {...params} label="Minuto" />}
          />
        </Grid>
      </Grid>

      <div>Hora de salida del trabajo</div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            style={exitTimeHour.length > 0 ? undefined : redBorder}
            disablePortal
            id="exitTimeHourInput"
            options={hours}
            // defaultValue={exitTimeHour}
            value={exitTimeHour}
            onChange={onExitTimeHourChange}
            renderInput={(params) => <TextField {...params} label="Hora" />}
          />
        </Grid>
        <Grid item xs={6}>
        <Autocomplete
            disablePortal
            id="exitTimeMinuteInput"
            options={minutes}
            // defaultValue={'0'}
            value={exitTimeMinute}
            onChange={onExitTimeMinuteChange}
            renderInput={(params) => <TextField {...params} label="Minuto" />}
          />
        </Grid>
      </Grid>

      <SeparatorView />

      <Button
        disabled={!validForm}
        fullWidth
        variant="contained"
        onClick={() => {
          // console.log('guardar...');
          client
            .post('/api/v1/journey/save', {
              place_a_id: user?.journey.place_a?.id,
              place_b_id: user?.journey.place_b?.id,
              time_to_arrive: `${timeToArriveHour}:${timeToArriveMinute}`,
              exit_time: `${exitTimeHour}:${exitTimeMinute}`,
              id: user?.journey.id,
            })
            .then((res: AxiosResponse<SaveJourneyResponse>) => {
              // console.log('FaqScreen', {res})
              if (res.data.errors && res.data.errors.length > 0) {
                console.error('FaqScreen', res.data.errors);
              } else {
                setTimeout(() => {
                  setOpen(true);
                }, 400);
                dispatch(getUserAction());
              }
            })
            .catch(e => {
              console.error('FaqScreen', e);
              return e;
            });
        }}
        >
          GUARDAR
        </Button>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="¡Los horarios han sido guardados!"
          action={action}
        />
        <Modal
          open={openImcompleteDataModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalBoxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <Alert severity="error">Datos incompletos</Alert>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Debe rellenar las informaciones resaltadas en rojo para completar los datos.
            </Typography>
            <Box sx={{textAlign: 'center', mt: 2}}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpenImcompleteDataModal(false)}
              >
                Completar
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* COMPLETE MODAL */}
        <Modal
          open={openCompleteDataModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalBoxStyle}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
              <Alert severity="success">¡Suscripción completada!</Alert>
            </Typography> */}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Ha completado el Registro. Cuando se conforme su Grupo, recibirá una notificación para confirmar la suscripción.
            </Typography>
            <Box sx={{textAlign: 'center', mt: 2}}>
              <Button
                variant="contained"
                color="success"
                onClick={() => setOpenCompleteDataModal(false)}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Modal>
    </Container>
  );
}

const modalBoxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '40%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  // border: '2px solid red',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
  m: 4
};

const redBorder: CSS.Properties = {
  borderWidth: '1px',
  borderColor: 'red',
  borderStyle: 'solid',
  borderRadius: '5px'
};

const subTitle: CSS.Properties = {
  textAlign: 'center'
};

const priceStr: CSS.Properties = {
  textAlign: 'center'
};

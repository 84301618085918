import {
  Alert,
  Container,
  Typography,
} from "@mui/material";
import { appMarginTop } from "../../assets/css/globalCss";
import CSS from "csstype";
import img from '../../assets/images/logo_tup.jpg';

export default function HomeScreen() {

  return (
    <Container maxWidth="sm" style={appMarginTop}>
      <div style={imageCont}>
        <img
          src={img}
          alt='TUP'
          style={imageStyle}
        />
      </div>
      <Typography variant="h4" style={title}>CONDICIONES</Typography>
      <ul>
        <li>Traslados de ida y vuelta de la <strong>CASA</strong> al <strong>TRABAJO</strong>.</li>
        <li>Medios de Transporte: Vans y Minibuses con A.A.</li>
        <li>Conformación de Grupos:
          <ul>
            <li>por zona de orígen</li>
            <li>por zona de destino</li>
            <li>por horario</li>
          </ul>
        </li>
        <li>Servicio mensual (lunes a viernes)</li>
        <li>Modalidades y Precios del servicio (hasta 15 Km):
          <ul>
            <li>Ida y vuelta: Gs. 600.000</li>
            <li>Solo Ida / Solo vuelta: Gs. 350.000</li>
          </ul>
        </li>
        <li>Registrarse y reservar. Deberá especificar los puntos de orígen, destino y horarios.</li>
      </ul>
      <Alert severity="info">
        <strong>Obs.: Cuando se complete su Grupo, recibirá una notificación para CONFIRMAR el servicio.</strong>
        <p>Puede optar por formas de pago mensual o quincenal.</p>
      </Alert>

    </Container>
  );
}

const title: CSS.Properties = {
  textAlign: 'center'
};

const imageStyle: CSS.Properties = {
  width: "50%",
  margin: 'auto',
};

const imageCont: CSS.Properties = {
  textAlign: 'center',
  marginBottom: '15px',
};
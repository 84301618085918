import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CSS from "csstype";


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div style={container}>
      {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>¿Quiénes somos?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <strong>Transporte Urbano Privado</strong> (TUP), es un emprendimiento de <strong>MobiUp</strong> (Picco SACCI), empresa pionera en emprendimientos digitales en Paraguay con más de 20 años en el rubro.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>¿Qué es TUP?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <strong>TUP</strong> es una plataforma tecnológica para reservar, abonar y utilizar el servicio de traslado compartido en buses, minibuses, vans, etc. TUP no es un proveedor de servicios de transporte, sino una opción tecnológica para generar el vínculo entre el Solicitante y el Conductor, no existiendo ninguna relación laboral ni de negocio con el/los Conductor/es.
          </Typography>
          <Typography>
            El sistema TUP permite que el Solicitante, previa registración, pueda solicitar los servicios de traslado compartido a un Conductor, contactándolo en forma directa. La utilización del servicio TUP será sin costo alguno para el Solicitante.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>¿Porqué utilizar TUP?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Porque es económico, confiable y rápido. Usted contará con opciones de servicios de transporte conforme con sus necesidades de traslado. Utilizando una plataforma que le permitirá:
          </Typography>
          <ul style={decorationNone}>
            <li>seleccionar el servicio deseado conociendo de antemano el precio conocer de antemano los datos del Conductor asignado estar al tanto del estado del servicio (agendado, en curso, terminado) poder pagar el servicio, desde la App, a través de medios electrónicos podrá visualizar y descargar la factura del servicio, desde la App o email podrá calificar el servicio y al conductor asignado contará con el histórico de servicios contratados.</li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            ¿Quién puede usar TUP?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lo pueden usar personas particulares que requieran servicios de transporte según las opciones disponibles.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>
            ¿Dónde puedo usar el servicio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            TUP inicialmente, prestará servicios en zonas de la ciudad de Asunción y Gran Asunción, con la intención de expandirse, en el menor tiempo posible, a otras zonas y ciudades vecinas. Se contará con una permanente información acerca de la ampliación de cobertura.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>
            ¿Cuándo puedo comenzar a usarlo?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Una vez descargada la Aplicación Móvil, deberá realizar el proceso de registro como nuevo usuario (Particular o Empresa). Finalizado el registro, podrá iniciar el uso de TUP.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>
            ¿Tengo que pagar algo por el registro y por el uso?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No. El registro y el uso de la aplicación móvil y la web son totalmente gratuitas.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography>
            ¿Qué tipos de servicios puedo solicitar?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Inicialmente, TUP habilitará el servicio de Traslados de CASA/TRABAJO/CASA. Posteriormente, serán habilitadas otras opciones de servicios, que serán comunicadas oportunamente.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography>
            ¿Cuánto cuestan los servicios de TUP?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Para el servicio de Traslados de CASA/TRABAJO/CASA, cuya modalidad de cobro es mensual, se ha fijado en Gs. 600.000, para distancias de hasta 15 km. Para distancias mayores, será fijada en oportunidad de su requerimiento.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
          <Typography>
            ¿Cómo registro una cuenta?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Debe Ingresar a la Aplicación Móvil o a https://app.tup.com.py/registro, completar el formulario de Registro y, definir los puntos de búsqueda (CASA) y destino (TRABAJO), así como los horarios de entrada y salida del destino (TRABAJO).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
          <Typography>
            ¿Quién realizará la prestación del servicio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Los servicios serán prestados por vehículos particulares (vans, minibuses), habilitados para el servicio de transporte de personas, debidamente registrados en la Plataforma TUP.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
          <Typography>
            ¿Cómo sé que es un Conductor competente?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Los Conductores registrados en TUP, deben contar con Registro de Conducir de Categoría A o superior, lo cual garantiza que poseen la habilitación para la prestación de servicios de transporte de personas. Al finalizar el servicio, cada Cliente podrá calificar al Conductor con 1 y hasta 5 estrellas, según su conformidad con el servicio recibido. Esta calificación será utilizada por la plataforma al momento de realizar las asignaciones. Solo podrán tener asignaciones aquellos que cuenten con un promedio de calificación 4 o superior.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
          <Typography>
            ¿Puedo variar los puntos definidos de búsqueda y destino?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Inicialmente no será factible realizar modificaciones. Más adelante podría habilitarse la modificación pero, con vigencia a partir del inicio del siguiente mes.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
          <Typography>
            ¿Porqué se pide indicar los horarios de entrada y salida del TRABAJO?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Los horarios de entrada y salida son claves para estructurar el servicio. El horario de entrada establece la hora tope en la cual cada usuario debe llegar a su lugar de destino (TRABAJO). Asimismo, el horario de salida, indica el inicio del trayecto de retorno con la búsqueda de los componentes del Grupo a trasladar.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary aria-controls="panel14d-content" id="panel14d-header">
          <Typography>
            ¿Cuándo estaré habilitado para recibir el servicio?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Es necesario estar Registrado en TUP. Una vez que reciba la notificación de la conformación de su Grupo, deberá realizar el pago del importe mensual, que le dará derecho al servicio, por el período de un mes o, el período de tiempo remanente del mes, conforme con el momento de realización del pago.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
        <AccordionSummary aria-controls="panel15d-content" id="panel15d-header">
          <Typography>
            ¿Hasta cuándo puedo realizar el pago mensual?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Siendo un servicio mensual, para tener habilitado el servicio, deberá realizar el pago dentro de los primeros 5 días de cada mes. El no realizar el pago, lo inhabilitará para recibir el servicio y TUP podrá disponer de su lugar dentro del Grupo.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
        <AccordionSummary aria-controls="panel16d-content" id="panel16d-header">
          <Typography>
            ¿Cuáles son las formas de pago aceptadas por TUP?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            TUP tiene habilitados medios de pagos electrónicos. Los pagos podrán realizarse desde la App o web de TUP utilizando tarjetas de débito, crédito, a través de la Administradora BANCARD y, las billeteras electrónicas Billetera PERSONAL y TIGO Money. Podrán incorporándose nuevas modalidades de pago que serán debidamente comunicadas.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
        <AccordionSummary aria-controls="panel17d-content" id="panel17d-header">
          <Typography>
            Facturación del servicio
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            TUP realizará la facturación del servicio, por cuenta y orden de los CONDUCTORES asignados a cada traslado. Un link para descargar la factura será remitido por mail, por lo cual, el Usuario deberá especificar y mantener actualizada la información para facturación, en su perfil.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const decorationNone: CSS.Properties = {
  listStyle: 'none'
};

const container: CSS.Properties = {
  paddingBottom: '2rem',
};

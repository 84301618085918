import { Edit } from "@mui/icons-material";
import PaymentIcon from '@mui/icons-material/Payment';
import {
  Button,
    Card,
    CardActionArea,
    CardContent,
    Container,
    Grid,
    Typography,
  } from "@mui/material";
import axios from 'axios';
import qs from 'qs';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { appMarginTop } from "../../assets/css/globalCss";
import { ACCESS_TOKEN_KEY } from "../../data/config/services/Client";
import { useAppSelector } from "../../data/redux/stores/hooks";
import { USER_RESET } from "../../data/redux/types/user";
import { registerLink } from "../../routes";
// import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function AccountScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useAppSelector(selector => selector.userReducer.user);
  return (
    <Container maxWidth="sm" style={appMarginTop}>
      <Card>
        {/* <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              R
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title="Shrimp and Chorizo Paella"
          subheader="September 14, 2016"
        /> */}
        <CardActionArea>

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {user?.name} {user?.lastname}
            </Typography>
            <div style={{textAlign: 'right'}}>
              <div
                style={{
                  margin: 0,
                  textDecoration: 'none',
                  padding: '5px',
                  border: '2px #999 solid',
                  borderRadius: '6px',
                  float: "right"
                }}
                onClick={() => {
                  history.push(registerLink.path);
                }}
                >
                  <div>
                    Editar
                    <Edit
                      fontSize="small"
                      style={{
                        marginBottom: '-4px'
                      }}
                      />
                  </div>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                Documento:
              </Grid>
              <Grid item xs={8}>
                {user?.document}
              </Grid>
              <Grid item xs={4}>
                Email:
              </Grid>
              <Grid item xs={8}>
                {user?.email}
              </Grid>
              <Grid item xs={4}>
                CI:
              </Grid>
              <Grid item xs={8}>
                {user?.document}
              </Grid>
              <Grid item xs={4}>
                RUC:
              </Grid>
              <Grid item xs={8}>
                {user?.ruc} / {user?.denomination}
              </Grid>
              <Grid item xs={4}>
                Teléfono:
              </Grid>
              <Grid item xs={8}>
                {user?.phone}
              </Grid>
            </Grid>
            {/* <Typography variant="body2" color="text.secondary">
              Datos relacionados a la cuenta.
            </Typography> */}
          </CardContent>
        </CardActionArea>
      </Card>
      <Button
        style={{width: '100%'}}
        size="small"
        onClick={() => {
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          dispatch({type: USER_RESET});
          window.location.href='./';
        }}
        >
        Cerrar Sesión
      </Button>

      <Card>
        {/* <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              R
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title="Shrimp and Chorizo Paella"
          subheader="September 14, 2016"
        /> */}
        <CardActionArea>

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Pagos
            </Typography>
            <div style={{flex:1}}>
              Realizar el pago vía bandcard
            </div>
            <div style={{textAlign: 'right'}}>
              <div
                style={{
                  margin: 0,
                  textDecoration: 'none',
                  padding: '5px',
                  border: '2px #999 solid',
                  borderRadius: '6px',
                  float: "right"
                }}
                onClick={() => {
                  axios
                    .post("https://www.mobipuja.com/pujanew/api/v1/index.php/credito/compra", qs.stringify({
                      'userid': '1',
                      'cantidad': '1',
                      'bancard': '8',
                      'tigo': '',
                      'personal': '',
                      'monto': '200' 
                    }))
                    .then(response => {
                      console.log({response});
                    })
                    .catch(error => {
                      console.error(error);
                    })
                }}
                >
                  <div>
                    Pagar
                    <PaymentIcon
                      fontSize="small"
                      style={{
                        marginBottom: '-4px'
                      }}
                      />
                  </div>
              </div>
            </div>
            
            {/* <Typography variant="body2" color="text.secondary">
              Datos relacionados a la cuenta.
            </Typography> */}
          </CardContent>
        </CardActionArea>
      </Card>

    </Container>
  );
}
  